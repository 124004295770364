import React from "react";

import { ApolloClient, InMemoryCache } from "apollo-boost";
import Cookies from "js-cookie";

import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";

import { Box } from "@chakra-ui/react";
import { toast } from "react-toastify";
// import Cookie from "js-cookie";

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      if (message === "Not Authorized, Please login again") {
        Cookies.remove("x-auth");
        localStorage.clear();
        window.location.href = "/login";
        return message;
      }
      return message;
    });
  }

  if (networkError) {
    toast.error(({ closeToast }) => <Box px="3">Oops, Network error</Box>);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("x-auth");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});
const link = ApolloLink.from([errorLink, authLink, uploadLink]);

const client = new ApolloClient({
  cache,
  link,
});

cache.writeData({
  data: {
    isLoggedIn: !!Cookies.get("x-auth"),
  },
});

export default client;
