import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },

  primary: {
    50: "#e2f2ff",
    100: "#bbd4f7",
    200: "#92b8ed",
    300: "#679be4",
    400: "#3e7fdb",
    500: "#2665c2",
    600: "#1b4f98",
    700: "#10386d",
    800: "#062244",
    900: "#000b1c",
  },

  // fontSizes: {
  //   xs: "0.75rem",
  //   sm: "0.875rem",
  //   md: "1rem",
  //   lg: "1.125rem",
  //   xl: "1.25rem",
  //   "2xl": "1.5rem",
  //   "3xl": "1.875rem",
  //   "4xl": "2.25rem",
  //   "5xl": "3rem",
  //   "6xl": "4rem",
  // },
};

const fonts = {
  heading:
    'Trueno,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  body:
    'Trueno,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  mono: "Menlo, monospace",
};

const customTheme = extendTheme({ colors, fonts });

export { customTheme };
