import Cookies from "js-cookie";
import { Redirect, Route, useLocation } from "react-router-dom";


const ProtectedRoute = ({ component: Component, loggedIn, path, ...rest }) => {
    let location = useLocation();

    const isAuth = () => {
        const token = Cookies.get("x-auth");
        if (token) {
            return true;
        }
        return false;
    };
    //const isAuthh = false;
    //console.log('am reaching here ohhhhh');
    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                return isAuth() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: location,
                                },
                            }}
                        />
                    );
            }}
        />
    );
};

export default ProtectedRoute;