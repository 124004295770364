import React from "react";
import { Grid, Box, Text, Flex, Button } from "@chakra-ui/react";
// import Empty from "../assets/empty.placeholder.svg";

interface Props {
  clearError?: any;
  title?: string;
  onClick?: any;
}

const Error: React.FC<Props> = () => (
  <Grid templateColumns="1fr" templateRows="100vh">
    <Box
      d="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      p="2rem"
    >
      <Text>Oops — something went wrong.</Text>
      <Text>Try refreshing this page again</Text>
    </Box>
  </Grid>
);

export const NetworkError: React.FC<Props> = ({ title, onClick }) => (
  <Flex
    flexDir="column"
    alignItems="center"
    py="10"
    textAlign="center"
    minHeight="90vh"
  >
    {/* <Image src={Empty} size="300px" alt="" /> */}
    <Text fontSize="xl" fontWeight="bold">
      {title}
    </Text>
    <Text marginBottom="8" color="gray.400">
      Unable to complete your request at the moment.
    </Text>
    <Button
      variantColor="blue"
      colorScheme="outline"
      borderRadius="8px"
      size="lg"
      onClick={onClick}
    >
      Try again
    </Button>
  </Flex>
);

interface IErrorBoundaryProps {
  children?: JSX.Element;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState,
  any
> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service    logErrorToMyService(error, errorInfo);
    // console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI      return <h1>Something went wrong.</h1>;    }
      return <Error />;
    }
    return this.props.children;
  }
}
