import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./Styles/font.css";
import "./Styles//datepicker.css";

// react-router
import { BrowserRouter } from "react-router-dom";

// chakra
// 1. import `ChakraProvider` component
import { ChakraProvider, CSSReset } from "@chakra-ui/react";

// apollo
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/client";
import { customTheme } from "./chakra/theme";
import SimpleReactLightbox from "simple-react-lightbox";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  dsn: "https://9dfb49ea907f4a77868882b1af820acf@o921676.ingest.sentry.io/5923577",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const Root = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </ChakraProvider>
    </BrowserRouter>
  </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
// const rootElement = document.getElementById("root");
// ReactDOM.createRoot(rootElement).render(<Root />);
