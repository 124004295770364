import React, { Fragment, lazy, Suspense } from "react";
// Libraries
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ErrorBoundary
import ErrorBoundary from "./components/Errors/ErrorBoundary.tsx";
// Components
import { Loader } from "./components/UI/Loader.tsx";
import ProtectedRoute from "./helpers/ProtectedRoute";
import "./Styles/App.css";

// Routes
const Login = lazy(() => import("./components/Auth/Login"));
const ForgotPassword = lazy(() => import("./components/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/Auth/ResetPassword"));
// const HomePage = lazy(() => import("./components/Home/HomePage"));
const Home = lazy(() => import("./components/Home/Home"));
const SetLibrary = lazy(() => import("./components/Library data/SetLibrary"));
const EditLibrary = lazy(() => import("./components/Library data/EditLibrary"));
const Success = lazy(() => import("./components/Library data/Pages/Success"));
const Data = lazy(() => import("./components/Home/Data"));
const Signup = lazy(() => import("./components/Auth/Signup"));

const App = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Switch>
            <Fragment>
              <Route exact path="/login" component={Login} />
              {/* <Route exact path="/signup" component={Auth} /> */}
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              {/* <Route
                exact
                path="/signup-success"
                component={SignupSuccessful}
              /> */}

              {/* <Route exact path="/home-page">
                <HomePage />
              </Route> */}
              <ProtectedRoute exact path="/makeEntry" component={SetLibrary} />
              {/* <ProtectedRoute exact path="/makeEntry">
                <SetLibrary />
              </ProtectedRoute> */}

              <ProtectedRoute exact path="/editEntry/:id" component={EditLibrary} />

              <ProtectedRoute exact path="/" component={Home} />
              {/* 
              <Route exact path="/">
                <Home />
              </Route> */}

              <ProtectedRoute exact path="/entry/:id" component={Data} />

              <Route exact path="/sucess">
                <Success />
              </Route>
            </Fragment>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
